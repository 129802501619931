body {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    line-height: 1em;
    min-height: 100%;
    background-color: var(--light-gray);
}
html{
    overflow-y: scroll;
}
:root {
    --page-max-width: 1300px;
    /* Colors: */
    --grey: #767676;
    --light-gray: #f7f7f7;
    --almost-black: #18212E;
    --unnamed-color-ff00e2: #ff00e2;
    --black: #1b1c1d;
    --brown: #a5673f;
    --pink: #e03997;
    --violet: #6435c9;
    --blue: #2185d0;
    --teal: #00b5ad;
    --green: #21ba45;
    --olive: #b5cc18;
    --yellow: #FEC953;
    --orange: #f2711c;
    --red: #db2828;
    --purple: #a333c8;
    --chip-active: #678fac;
    --chip-hover: #2286fc;
    --chip-press: #678fac;
    --chip-disabled: #b8c4cc;
    --success-message-text: #1e561f;
    --error-message-text: #973937;
    --success-message-background: #fcfff5;
    --success-message-border: #a8c599;
    --error-message-background: #fff6f6;
    --error-message-border: #ebcccc;
    --warning-message-background: #fffaf3;
    --warning-message-border: #ccbea0;
    --warning-message-text: #7a4d05;
    --information-message-background: #f8ffff;
    --information-message-border: #bedfe6;
    --information-message-text: #0e566c;
    --table-alternate-background: #fafafb;
}
